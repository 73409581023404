import React, { useState } from "react";
import styles from "./Footer.module.css";

function Footer() {

  return (
    <div className={styles.footerContainer}>
      <footer>
        <p>© easychords 2024. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default Footer;
