import React from "react";
import styles from "./UserAvatar.module.css";

function UserAvatar({ name, onClick }) {

  const handleNameParts = (fullName) => {
    if (!fullName) return "";

    const nameParts = fullName.split(" ");
    const firstLetter = nameParts[0].charAt(0).toUpperCase();
    const secondLetter = nameParts.length > 1 ? nameParts[1].charAt(0).toUpperCase() : "";
    
    return `${firstLetter}${secondLetter}`;
  };

  return (
    <div className={styles.userAvatar} onClick={onClick}>
      {handleNameParts(name)}
    </div>
  );
}

export default UserAvatar;
