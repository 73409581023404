import React, { useState } from "react";
import styles from "./UserInput.module.css";
import { useChordsData } from "../../ChordsDataContext";
import { usePrompt } from "../../promptContext";
import CoolDown from "./CoolDown/CoolDown";
import { BiSend } from "react-icons/bi";
import Terms from "./Terms/Terms";
import {
  validatePromptCount,
  addToPromptCount,
} from "../../utils/promptsUtils";

import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../firebaseConfig";
import { signInAnonymouslyAndStoreId } from "../../utils/authUtils";

function UserInput({
  onSubmit,
  onClick,
  onChange,
  value,
  setClicked,
  setIsLoadingGif,
  coolDown,
  handleCoolDown,
  handleSubmitBtnClick,
}) {
  const [inputClick, setInputClick] = useState(false);
  const [chordsTextInput, setChordsTextInput] = useState("");
  const [charCount, setCharCount] = useState(0);
  const { chordsData, updateChordsData } = useChordsData();
  const { updatePrompt } = usePrompt();
  const [promptError, setPromptError] = useState(false);
  const [promptErrorMsg, setPromptErrorMsg] = useState("");
  const CHAR_LIMIT = 100;

  const handleClick = (setClicked) => {
    if (setClicked) {
      setInputClick(true);
      setPromptError(false);
    }
  };

  const validateUserPrompt = () => {
    if (!chordsTextInput.trim()) {
      setPromptError(true);
      setPromptErrorMsg("Prompt can't be empty.");
      return false;
    } else {
      setPromptError(false);
      setPromptErrorMsg("");
      return true;
    }
  };

  const handelSubmit = async (event) => {
    event.preventDefault();
    if (!validateUserPrompt()) {
      return;
    }

    if (!validatePromptCount()) {
      handleSubmitBtnClick(true);
      return;
    }
    setIsLoadingGif(true);

    const generateChordsFunction = httpsCallable(functions, "generateChords");

    if (!auth.currentUser) {
      await signInAnonymouslyAndStoreId();
    }

    generateChordsFunction({ chordsTextInput: chordsTextInput })
      .then((res) => {
        updateChordsData(res.data);
        setIsLoadingGif(false);
        onSubmit(res.data);
        addToPromptCount();
        if (chordsTextInput.length > 0) {
          updatePrompt(chordsTextInput);
        }
        handleCoolDown(30);
        handleSubmitBtnClick(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    if (input.length <= CHAR_LIMIT) {
      setChordsTextInput(input);
      setCharCount(input.length);
      onChange(input);
      setPromptError(false);
    }
  };

  return (
    <form className={styles.formContainer} onSubmit={handelSubmit}>
      {coolDown ? (
        <CoolDown chordsData={chordsData} />
      ) : (
        <>
          <div
            className={`${styles.inputContainer} ${
              inputClick && styles.InputClicked
            } 
              ${coolDown && styles.borderForm}
              ${promptError && styles.shake}`}
          >
            <input
              type="text"
              placeholder="What's on your mind?"
              value={value}
              minLength={3}
              className={`${styles.textInput} ${
                promptError
                  ? styles.errorPlaceholder
                  : styles.defaultPlaceholder
              }`}
              onChange={handleInputChange}
              onClick={(event) => {
                onClick(event);
                handleClick(event);
              }}
              onBlur={() => {
                setClicked(false);
                setInputClick(false);
              }}
            />
            {promptError && (
              <p className={styles.promptErrorMsg}>{promptErrorMsg}</p>
            )}
            <button type="submit" className={styles.button}>
              <BiSend className={styles.sendIcon} />
            </button>
          </div>
        </>
      )}
      <div className={styles.underSection}>
        <div className={styles.termsAndPolicy}>{inputClick && <Terms />}</div>
        <div className={styles.charLimit}>
          <p style={{ color: charCount === CHAR_LIMIT ? "red" : "grey" }}>
            {charCount} / {CHAR_LIMIT}
          </p>
        </div>
      </div>
    </form>
  );
}

export default UserInput;
