import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
import icon from "../../../Assets/Icons/navIcon.png";
import Button from "./Button/Button";
import SingInWithGoogle from "./SignIn/SingInWithGoogle";
import closeIcon from "../../../Assets/nav/closeIcon.png";
import menuIcon from "../../../Assets/nav/menuIcon.png";

function Navbar({
  handleFeedbackClick,
  setFeedbackClicked,
  setBtnClicked,
  feedbackBtnText,
  btnClicked,
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const Menu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLaterClicked = () => {
    setFeedbackClicked(false);
    setBtnClicked(false);
  };

  return (
    <div className={styles.navbarContainer}>
      <nav className={styles.content}>
        <div className={styles.leftSideNav}>
          <img src={icon} className={styles.navIcon} />
          <Link to="/" className={styles.title}>
            easychords
          </Link>
        </div>
        <div
          className={btnClicked ? styles.blurRightSide : styles.rightSideNav}
        >
          <button className={styles.laterBtn} onClick={handleLaterClicked}>
            Later
          </button>
          <div className={styles.signInBtn}>
            <SingInWithGoogle />
          </div>
          <div className={styles.menu}>
            <img
              className={styles.menuBtn}
              src={menuOpen ? closeIcon : menuIcon}
              alt="menu-button"
              onClick={Menu}
            />
            <ul
              className={`${styles.menuItems} ${
                menuOpen && !btnClicked ? styles.menuOpenStyle : ""
              }`}
              onClick={Menu}
            >
              <div className={styles.btns}>
                <li>
                  <Button
                    value="contact us"
                    text={feedbackBtnText}
                    handleClick={handleFeedbackClick}
                  />
                </li>
                <li className={styles.border}>|</li>
                <li>
                  <Button
                    value="Developers"
                    text="Developers"
                    // handleClick={""}
                  />
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
