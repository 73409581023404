import React, { useState } from "react";
import styles from "./ChordComposer.module.css";
import UserInput from "../../Components/UserInput/UserInput";
import { useChordsData } from "../../ChordsDataContext";
import ChordsDisplay from "../../Components/ChordsDisplay/ChordsDisplay";
import Loading from "../../Components/Default/Loading/Loading";
import { Link } from "react-router-dom";
import { usePrompt } from "../../promptContext";
import axios from "axios";
import {
  validatePromptCount,
  addToPromptCount,
} from "../../utils/promptsUtils";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../firebaseConfig";
import { signInAnonymouslyAndStoreId } from "../../utils/authUtils";

function ChordComposer({ handleCoolDown, coolDown, loginRequired, handleSubmitBtnClick }) {
  const [isLoadingGif, setIsLoadingGif] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [chordsTextInput, setChordsTextInput] = useState("");
  const { chordsData, updateChordsData } = useChordsData();
  const { prompt } = usePrompt();

  const handleInputChange = (value) => {
    setChordsTextInput(value);
  };

  const handleInputClick = () => {
    setClicked(true);
  };

  const handleFavorites = () => {
    console.log("This prompt add to your favorite list!");
  };

  const downloadSource = () => {
    axios
      .get(chordsData.midi_file_url, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${chordsData.title}.mid`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.log(chordsData.midi_file_url);
        console.error("Error downloading source:", error);
      });
  };

  const fetchNewChords = async () => {
    if (!validatePromptCount()) {
      console.error("You have exceeded the limit of 10 prompts");
      return;
    }
    setIsLoadingGif(true);

    const generateChordsFunction = httpsCallable(functions, "generateChords");

    if (!auth.currentUser) {
      await signInAnonymouslyAndStoreId();
    }

    generateChordsFunction({ chordsTextInput: prompt })
      .then((res) => {
        updateChordsData(res.data);
        setIsLoadingGif(false);
        addToPromptCount();
      })
      .catch((err) => {
        console.error("Error fetching new chords:", err);
        setIsLoadingGif(false);
      });
  };

  if (chordsData === null) {
    return (
      <div className={styles.chordComposerContainer} id="chordComposer">
        <p>
          Sorry, it seems like you don't have any data in store. Press here to
          return to the <Link to="/">Homepage.</Link>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.chordComposerContainer} id="chordComposer">
      {isLoadingGif ? (
        <Loading />
      ) : (
        <>
          <ChordsDisplay
            prompt={prompt}
            chordsData={chordsData}
            downloadSource={downloadSource}
            fetchNewChords={fetchNewChords}
            handleFavorites={handleFavorites}
          />
          <div className={styles.inputContainer}>
            <UserInput
              onChange={handleInputChange}
              onSubmit={() => {}}
              onClick={handleInputClick}
              setClicked={setClicked}
              setIsLoadingGif={setIsLoadingGif}
              value={chordsTextInput}
              handleCoolDown={handleCoolDown}
              coolDown={coolDown}
              loginRequired={loginRequired}
              handleSubmitBtnClick={handleSubmitBtnClick}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ChordComposer;
