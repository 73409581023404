import { useState, useRef, useEffect } from "react";
import styles from "./SliderAudio.module.css";
import synth from "synth-js";
import { FaPauseCircle } from "react-icons/fa";
import { IoPlayCircle } from "react-icons/io5";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { MdDownloading } from "react-icons/md";
import { LuBookmark } from "react-icons/lu";
import { BsArrowRepeat } from "react-icons/bs";

function SliderAudio({ midiFile, downloadSource, fetchNewChords }) {
  const [position, setPosition] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [wavUrl, setWavUrl] = useState(null);

  const audioRef = useRef();
  const thumbRef = useRef();

  useEffect(() => {
    if (midiFile) {
      fetch(midiFile)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const wav = synth.midiToWav(buffer).toBlob();
          const url = URL.createObjectURL(wav);
          setWavUrl(url);

          const audioElement = new Audio(url);
          audioElement.addEventListener("loadedmetadata", () => {
            setDuration(audioElement.duration);
          });

          return () => URL.revokeObjectURL(url);
        });
    }
  }, [midiFile]);

  useEffect(() => {
    setPosition(percentage);
  }, [percentage]);

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.1;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(0);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  const secondsToHms = (seconds) => {
    if (!seconds) return "00:00";

    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }

    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}h ${min}m ${sec}s`;
    } else if (min == 0) {
      return `00:${sec}`;
    } else {
      return `${min}m ${sec}s`;
    }
  };

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.thumb} ref={thumbRef}></div>
      <div className={styles.sliderControl}>
        <div className={styles.buttons}>
          <Tippy
            content={isPlaying ? "Pause" : "Play"}
            placement="top"
            animation="scale"
          >
            <div className={styles.PlayPauseIcon}>
              {isPlaying ? (
                <FaPauseCircle className={styles.icon} onClick={play} />
              ) : (
                <IoPlayCircle className={styles.icon} onClick={play} />
              )}
            </div>
          </Tippy>
        </div>
        <input
          type="range"
          value={position}
          step="0.01"
          className={styles.range}
          onChange={onChange}
        />
        <div className={styles.times}>
          <div className={styles.timer}>
            {secondsToHms(currentTime)} /&nbsp;
          </div>
          <div className={styles.timer}>{secondsToHms(duration)}</div>
        </div>
        <audio
          ref={audioRef}
          onTimeUpdate={getCurrDuration}
          onLoadedData={(e) => {
            setDuration(e.currentTarget.duration.toFixed(2));
          }}
          src={wavUrl}
        ></audio>
      </div>
      <div className={styles.btns}>
        <div className={styles.firstLineBtns}>
          <Tippy content="Re-generate" placement="top" animation="scale">
            <div className={styles.btn} onClick={fetchNewChords}>
              <BsArrowRepeat className={styles.regenerateIcon} />
              <p>Regenerate</p>
            </div>
          </Tippy>

          <Tippy content="Download midi" placement="top" animation="scale">
            <div className={styles.btn} onClick={downloadSource}>
              <MdDownloading className={styles.downloadIcon} />
              <p>Download MIDI</p>
            </div>
          </Tippy>
        </div>

        <div className={styles.secondLineBtns}>
          <Tippy content="Save" placement="top" animation="scale">
            <div className={styles.btn}>
              <LuBookmark className={styles.saveIcon} />
              <p>Save</p>
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
}

export default SliderAudio;
