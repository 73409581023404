import React from "react";
import styles from "./Button.module.css";

const Button = ({
  value,
  text,
  handleClick,
  handleOnMouseOver,
  handleOnMouseOut,
}) => {
  return (
    <button
      value={value}
      className={styles.btn}
      onClick={handleClick}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
    >
      {text}
    </button>
  );
};

export default Button;
