import React, {useState} from "react";
import styles from "./Terms.module.css";

function Terms() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  return (
    <div className={styles.container}>
      <p className={styles.content}>
        By sending a message you agree our&nbsp;
        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <a href="">Terms</a>
        </div>
        &nbsp;and&nbsp;
        <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <a href="">Privacy Policy</a>
        </div>
      </p>
    </div>
  );
}

export default Terms;
