import React, { useState } from "react";
import styles from "./ChordsDisplay.module.css";
import ChordList from "../ChordList/ChordList";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { CiCirclePlus } from "react-icons/ci";
import { Link } from "react-router-dom";
import { TbExclamationMark } from "react-icons/tb";
import SliderAudio from "../AudioPlayer/SliderAudio";

function ChordsDisplay({ chordsData, fetchNewChords, downloadSource, prompt }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.chordsDisplay}>
      <div>
        <p>"{prompt}"</p>
      </div>
      <div className={styles.header}>
        <h1 className={styles.title}>{chordsData.title}</h1>
        {/* <div
          className={styles.plusIconContainer}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <CiCirclePlus className={styles.addIcon} />
          {isHovered && (
            <div className={styles.mouseOver}>
              <div className={styles.mouseOverContent}>
                <TbExclamationMark className={styles.exclamationMark} />
                <span>coming soon</span>
              </div>
            </div>
          )}
        </div> */}
      </div>
      <h3
        className={styles.keyBPM}
      >{`${chordsData.key} - ${chordsData.bpm}`}</h3>
      <ChordList chords={chordsData.chords} />
      <div className={styles.sliderAudio}>
        <SliderAudio
          fetchNewChords={fetchNewChords}
          downloadSource={downloadSource}
          midiFile={chordsData.midi_file_url}
        />
      </div>
    </div>
  );
}

export default ChordsDisplay;
