import React, { useState, useEffect } from "react";
import styles from "./CoolDown.module.css";

function CoolDown({chordsData}) {
  const [seconds, setSeconds] = useState(chordsData.cooldown_seconds);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      let tmp = seconds;
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        tmp = tmp - 1;
        if (tmp === 0) {
          setIsActive(false);
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isActive]);

  const progressBarWidth = (seconds / chordsData.cooldown_seconds) * 100;

  return (
    <div className={styles.coolDownBar}>
      <p>{seconds}s</p>
      <div
        className={styles.progress}
        style={{ width: `${progressBarWidth}%`, transition: "width 1s linear" }}
      ></div>
    </div>
  );
}

export default CoolDown;
