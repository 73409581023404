import React, { createContext, useContext, useState, useEffect } from "react";

const UserPrompt = createContext();

export const PromptProvider = ({ children }) => {
  const [prompt, setPrompt] = useState(null);

  const updatePrompt = (newPrompt) => {
    let updatedPrompt = newPrompt; // Initialize updated prompt variable
    
    // Check if newPrompt starts and ends with double quotes
    if (newPrompt.startsWith('"') && newPrompt.endsWith('"')) {
        // Remove double quotes from newPrompt
        updatedPrompt = newPrompt.slice(1, -1);
    }
    
    setPrompt(updatedPrompt); // Set the updated prompt
    const serializedData = JSON.stringify(updatedPrompt);
    localStorage.setItem("promptData", serializedData);
};

  useEffect(() => {
    const storedPromptData = localStorage.getItem("promptData"); 
    if (storedPromptData !== null) {
      const deserializedData = JSON.parse(storedPromptData);
      setPrompt(deserializedData);
    }
  }, []);

  return (
    <UserPrompt.Provider value={{ prompt, updatePrompt }}>
      {children}
    </UserPrompt.Provider>
  );
};

export const usePrompt = () => {
  const context = useContext(UserPrompt); 
  if (!context) {
    throw new Error("usePrompt must be used within a PromptProvider"); 
  }
  return context;
};
