import {
  signInAnonymously,
  GoogleAuthProvider,
  signInWithPopup,
  linkWithPopup,
  connectAuthEmulator,
  signInWithCredential,
  signInWithRedirect,
  getRedirectResult,
  linkWithRedirect,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { functions } from "../firebaseConfig";

// connectAuthEmulator(auth, "http://127.0.0.1:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);

const isUserConnected = () => {
  return auth.currentUser !== null;
};

const signInAnonymouslyAndStoreId = async () => {
  try {
    const result = await signInAnonymously(auth);
    const userId = result.user.uid;
    const createdAt = result.user.metadata.creationTime;
    const userType = process.env.REACT_APP_USER_TYPE_VISITOR;

    localStorage.setItem("userId", userId);
    localStorage.setItem("userType", userType);

    const storeCredentialsInDBFunction = httpsCallable(
      functions,
      "storeCredentialsInDB"
    );
    storeCredentialsInDBFunction({
      userId,
      createdAt,
      userType,
    })
      .then((result) => {})
      .catch((error) => {
        console.error(
          "Error signing in anonymously in catch of store credentials:",
          error
        );
      });
  } catch (err) {
    console.error("Error signing in anonymously in buttom catch:", err);
  }
};

const storeUserCredentials = async (result) => {
  const userId = result.user.uid;
  const accessToken = await result.user.getIdToken();
  const userEmail = result.user.email;
  const name = result.user.displayName;
  const createdAt = result.user.metadata.creationTime;
  const lastSignIn = result.user.metadata.lastSignInTime;
  const userType = process.env.REACT_APP_USER_TYPE_USER;

  localStorage.setItem("userId", userId);
  localStorage.setItem("userType", userType);

  const storeCredentialsInDBFunction = httpsCallable(
    functions,
    "storeCredentialsInDB"
  );
  try {
    await storeCredentialsInDBFunction({
      userId,
      accessToken,
      userEmail,
      name,
      createdAt,
      lastSignIn,
      userType,
    });
  } catch (err) {
    console.error("error storing use credentials: ", err);
    throw err;
  }
};

const handleSignInWithGoogle = async (callBack) => {
  const provider = new GoogleAuthProvider();

  try {
    if (auth.currentUser) {
      linkWithRedirect(auth.currentUser, provider);
    } else {
      signInWithRedirect(auth, provider);
    }
    callBack();
  } catch (err) {
    console.error("Error during sign-in with Google:", err);
  }
};

const handleCredentailsInUseError = async (err) => {
  const credential = GoogleAuthProvider.credentialFromError(err);
  const deleteUserId = auth.currentUser.uid;
  let result;

  const deleteUserFromDBFunction = httpsCallable(
    functions,
    "deleteUserFromDataBase"
  );

  await deleteUserFromDBFunction({
    userId: deleteUserId,
  })
    .then(async () => {
      auth.currentUser.delete();
      result = await signInWithCredential(auth, credential);
    })
    .catch((err) => {
      console.error(
        "Error signing in anonymously in credentials already in used:",
        err
      );
    });
  return result;
};

const handleRedirectResult = async () => {
  let result;
  try {
    result = await getRedirectResult(auth);
    if (result) {
      await storeUserCredentials(result);
    }
  } catch (err) {
    if (
      err.code === process.env.REACT_APP_CREDENTIAL_ALREADY_IN_USE_ERROR_CODE
    ) {
      result = await handleCredentailsInUseError(err);
      await storeUserCredentials(result);
    } else {
      console.error("Error handling redirect result:", err);
    }
  }
};

const initAuth = async () => {
  await handleRedirectResult();
};

const signOut = () => {
  auth.signOut();
  localStorage.setItem("userType", process.env.REACT_APP_USER_TYPE_VISITOR);
};

export {
  signInAnonymouslyAndStoreId,
  handleSignInWithGoogle,
  signOut,
  isUserConnected,
  storeUserCredentials,
  initAuth,
};
