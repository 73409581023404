import React, { useRef, useState } from "react";
import styles from "./Feedback.module.css";
import emailjs from "@emailjs/browser";
import Sending from "../../Sending/Sending";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

function Feedback() {
  const [sent, setSent] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const form = useRef();
  const [feedbackError, setFeedbackError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [feedbackErrorMsg, setFeedbackErrorMsg] = useState("");
  const [userNameErrorMsg, setUserNameErrorMsg] = useState("");
  const [userEmailErrorMsg, setUserEmailErrorMsg] = useState("");

  const validateFeedbackInput = () => {
    if (!form.current.message.value.trim()) {
      setFeedbackError(true);
      setFeedbackErrorMsg("Please provide your feedback.");
    } else {
      setFeedbackError(false);
      setFeedbackErrorMsg("");
    }
    return feedbackError;
  };

  const validateUserName = () => {
    if (!form.current.user_name.value.trim()) {
      setUserNameError(true);
      setUserNameErrorMsg("Please enter your name.");
    } else {
      setUserNameError(false);
      setUserNameErrorMsg("");
    }
    return userNameError;
  };

  const validateUserEmail = () => {
    if (!form.current.user_email.value.trim()) {
      setUserEmailError(true);
      setUserEmailErrorMsg("Please enter a valid email.");
    } else {
      setUserEmailError(false);
      setUserEmailErrorMsg("");
    }
    return userEmailError;
  };

  const runValidationChecks = () => {
    validateFeedbackInput();
    validateUserName();
    validateUserEmail();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!runValidationChecks()) {
      console.log("Validation error");
      return;
    }

    emailjs
      .sendForm("service_udkn2ko", "template_cmevack", form.current, {
        publicKey: "_t72ldTV3l-3EHe6v",
      })
      .then(
        () => {
          setSent(true);
          setTimeout(() => {
            setMsgSent(true);
            setSent(false);
          }, 2000);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className={styles.container}>
      {msgSent ? (
        <div className={styles.finalMsg}>
          <IoCheckmarkCircleSharp className={styles.checkIcon} />
          <h1>Thank you!</h1>
          <p>We will get back to you in the next 24-48 hours.</p>
        </div>
      ) : (
        <>
          {sent ? (
            <>
              <Sending />
              {msgSent ? (
                <div>
                  <IoCheckmarkCircleSharp />
                  <h1>Thank you!</h1>
                  <p>We will get back to you in the next 24-48 hours.</p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className={styles.leftSide}>
                <div className={styles.Feedback}>
                  <h1>Feedback</h1>
                  <p>That’s how we improve and make easychords better.</p>
                </div>
                <div className={styles.ContactUs}>
                  <h1>Contact Us</h1>
                  <p>For business opportunities.</p>
                </div>
                <div className={styles.SayHi}>
                  <h1>Or just say Hi 👋</h1>
                  <p>We are always open to meet new people!</p>
                </div>
                <div className={styles.bottomMsg}>
                  <h1>We’d love to hear from you.</h1>
                </div>
              </div>

              <div className={styles.border}></div>

              <div className={styles.rightSide}>
                <h3 className={styles.title}>Tell us what you think!</h3>
                <div className={styles.forms}>
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className={styles.formContainer}
                  >
                    <textarea
                      name="message"
                      placeholder="Share your experience here"
                      cols="30"
                      rows="10"
                      maxLength={50}
                      minLength={3}
                      className={`${styles.textarea} ${
                        feedbackError && styles.shake
                      }`}
                    />
                    {feedbackError && (
                      <p className={styles.errorMsg}>{feedbackErrorMsg}</p>
                    )}
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Your Name"
                      maxLength={20}
                      minLength={3}
                      className={`${styles.input1} ${
                        userNameError && styles.shake
                      }`}
                    />
                    {userNameError && (
                      <p className={styles.errorMsg}>{userNameErrorMsg}</p>
                    )}
                    <input
                      type="email"
                      name="user_email"
                      placeholder="Your Email"
                      maxLength={20}
                      className={`${styles.input2} ${
                        userEmailError && styles.shake
                      }`}
                    />
                    {userEmailError && (
                      <p className={styles.errorMsg}>{userEmailErrorMsg}</p>
                    )}

                    <input type="submit" value="Send" className={styles.btn} />
                  </form>
                </div>
                <h3 className={styles.aboveBtnMsg}>
                  Your feedback help us improve <strong>easychords</strong>.{" "}
                  <br /> We will not spam your mailbox.
                </h3>
                <h3 className={styles.mailSection}>
                  Or email directly to{" "}
                  <a href="mailto:hello@easychords.ai">hello@easychords.co</a>
                </h3>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Feedback;
