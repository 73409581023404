const addToPromptCount = () => {
  let promptCount = parseInt(localStorage.getItem("promptCount") || 0);
  promptCount += 1;
  localStorage.setItem("promptCount", promptCount);
};

const validatePromptCount = () => {
  const userType = localStorage.getItem("userType") || null;
  if (userType === process.env.REACT_APP_USER_TYPE_VISITOR) {
    const promptCount = parseInt(localStorage.getItem("promptCount")) || 0;
    if (promptCount >= 10) {
      return false;
    }
  }
  return true;
};

export { addToPromptCount, validatePromptCount };
