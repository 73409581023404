import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCDOh-QZNmy1vmCE08xKjMx1GtV0551aVE",
  authDomain: "easychords-prod.firebaseapp.com",
  projectId: "easychords-prod",
  storageBucket: "easychords-prod.appspot.com",
  messagingSenderId: "340448387460",
  appId: "1:340448387460:web:22e86e8791c9fd9c2dfdd1",
  measurementId: "G-3GV5TEHEGY",
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);
export const auth = getAuth(app);
export default app;
