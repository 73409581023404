const cardsData = [
  {
    title: "morning dew",
    key: "G Major",
    bpm: "100",
    chords: [
      {
        chord: "G",
        degree: "I",
      },
      {
        chord: "D",
        degree: "V",
      },
      {
        chord: "Em",
        degree: "vi",
      },
      {
        chord: "C",
        degree: "IV",
      },
      {
        chord: "D",
        degree: "V",
      },
      {
        chord: "G",
        degree: "I",
      },
    ],
    midi_file_url:
      "https://storage.googleapis.com/ttmusic-generated-files/A1B2C3D4E5.mid",
    user_prompt: "morning dew",
    cooldown_seconds: "30",
    promptCount: 5,
  },
  {
    title: "sunshine",
    key: "C Major",
    bpm: "120",
    chords: [
      {
        chord: "C",
        degree: "I",
      },
      {
        chord: "G",
        degree: "V",
      },
      {
        chord: "Am",
        degree: "vi",
      },
      {
        chord: "F",
        degree: "IV",
      },
      {
        chord: "G",
        degree: "V",
      },
      {
        chord: "C",
        degree: "I",
      },
    ],
    midi_file_url:
      "https://storage.googleapis.com/ttmusic-generated-files/7D9JqX5Vb1.mid",
    user_prompt: "sunshine",
    cooldown_seconds: "30",
    promptCount: 6,
  },
  {
    title: "evening breeze",
    key: "A Minor",
    bpm: "95",
    chords: [
      {
        chord: "Am",
        degree: "i",
      },
      {
        chord: "E",
        degree: "V",
      },
      {
        chord: "Dm",
        degree: "iv",
      },
      {
        chord: "G",
        degree: "VII",
      },
      {
        chord: "C",
        degree: "III",
      },
      {
        chord: "Am",
        degree: "i",
      },
    ],
    midi_file_url:
      "https://storage.googleapis.com/ttmusic-generated-files/8U8PqZ6Hd2.mid",
    user_prompt: "evening breeze",
    cooldown_seconds: "30",
    promptCount: 5,
  },
  {
    title: "midnight blues",
    key: "B Minor",
    bpm: "85",
    chords: [
      {
        chord: "Bm",
        degree: "i",
      },
      {
        chord: "F#",
        degree: "V",
      },
      {
        chord: "Em",
        degree: "iv",
      },
      {
        chord: "A",
        degree: "VII",
      },
      {
        chord: "G",
        degree: "VI",
      },
      {
        chord: "Bm",
        degree: "i",
      },
    ],
    midi_file_url:
      "https://storage.googleapis.com/ttmusic-generated-files/9C3HnV5Bc3.mid",
    user_prompt: "midnight blues",
    cooldown_seconds: "30",
    promptCount: 3,
  },
];

export default cardsData;
