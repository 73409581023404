import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderExamples.css";
import { BsArrowRightCircle } from "react-icons/bs";
import SliderAudio from "../AudioPlayer/SliderAudio";
import cardsData from "../../CardsData";

function SliderExamples({
  handleMouseOver,
  handleMouseOut,
  handleClick,
  isHovered,
  clicked,
  downloadSource,
  midiFile,
  onExampleChange,
}) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (index) => onExampleChange(cardsData[index].user_prompt),
  };
  return (
    <div className="container">
      <Slider {...settings}>
        {cardsData.map((card, index) => (
          <div key={index} className="card">
            <div className="promptExample">
              <p>"{card.user_prompt}"</p>
            </div>

            <div className="cardContent">
              <div className="title">
                <h3>{card.title}</h3>
              </div>

              <div className="keyBpm">
                <p>
                  key &nbsp; <span>{card.key}</span>
                </p>
                <p>
                  bpm &nbsp; <span>{card.bpm}</span>
                </p>
              </div>

              <div className="chordsItem">
                {card.chords.map((element, index) => (
                  <li key={index} className="chordPair">
                    <div className="chord">{element.chord}</div>
                    <div className="degree">{element.degree}</div>
                  </li>
                ))}
              </div>

              <div className="sliderAudio">
                <SliderAudio
                  downloadSource={downloadSource}
                  midiFile={midiFile}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SliderExamples;
