import React from "react";
import { ScaleLoader } from "react-spinners";
import styles from "./Loading.module.css";

function Loading() {
  return (
    <div className={styles.loading}>
      <ScaleLoader color="white" height={60} margin={4} radius={2} width={6} />
    </div>
  );
}

export default Loading;
