import React from 'react'
import { ClipLoader } from "react-spinners";
import styles from "./Sending.module.css";

function Sending() {
  return (
    <div className={styles.sending}>
      <ClipLoader  color="white" />
      <p className={styles.p}>sending ...</p>
    </div>
  )
}

export default Sending