import React, { useState } from "react";
import styles from "./SignInRequired.module.css";
import logo from "../../Assets/Icons/navIcon.png";
import vImage from "../../Assets/Icons/image.png";
import features from "./features";
import SingInWithGoogle from "../Default/Navbar/SignIn/SingInWithGoogle";

function SignInRequired({ handleCloseSignInRequired }) {
  const handleExitClick = () => {
    handleCloseSignInRequired(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={logo} alt="logo" />
        <p>easychords</p>
        <div onClick={handleExitClick}>
          <button className={styles.exitBtn}>X</button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.leftSideContent}>
          <div className={styles.headerLeftSide}>
            <p>Log in to continue</p>
          </div>

          <p className={styles.btnsOptions}>
            Choose your Google account to sign in:
          </p>

          <div className={styles.btns}>
            <SingInWithGoogle />
            {/* <SingIn /> */}
          </div>

          <div className={styles.termsAndPrivacy}>
            By signing in you agree our&nbsp;<a href="">Terms</a>&nbsp;and&nbsp;
            <a href="">Privacy Policy</a>.
          </div>
        </div>

        <div className={styles.border}></div>

        <div className={styles.rightSideContent}>
          <div className={styles.headerRightSide}>
            <p>Logged in users enjoy:</p>
          </div>

          <div className={styles.featuresIncluded}>
            {features.map((feature, index) => (
              <div key={index} className={styles.featureItem}>
                <img src={vImage} />
                <p key={index}>{feature}</p>
              </div>
            ))}
          </div>

          <div className={styles.rightDownBorder}></div>

          <div className={styles.bottomRightContent}>
            <p>
              All features are currently available for <span>FREE</span>!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInRequired;
