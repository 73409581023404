import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import UserInput from "../../Components/UserInput/UserInput";
import ChordComposer from "../ChordComposer/ChordComposer";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Default/Loading/Loading";
import { FaRegLightbulb } from "react-icons/fa6";
import SliderExamples from "../../Components/SliderEx/SliderExamples";
import { auth } from "../../firebaseConfig";
import { getRedirectResult } from "firebase/auth";
import { storeUserCredentials } from "../../utils/authUtils";

function Homepage({
  handleCoolDown,
  coolDown,
  loginRequired,
  handleSubmitBtnClick,
}) {
  const [text, setText] = useState("");
  const [clicked, setClicked] = useState(false);
  const [isLoadingGif, setIsLoadingGif] = useState(false);
  const [chordsData, setChordsData] = useState("");
  const [handleExampleIndex, setHandleExampleIndex] = useState("morning dew");

  // useEffect(() => {
  //   const authInit = async () => {
  //     const userCred = await getRedirectResult(auth);
  //     console.log("-----------------------");
  //     console.log(userCred);
  //     if (userCred) {
  //       storeUserCredentials(userCred);
  //     }
  //   };
  //   authInit();
  // }, []);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setChordsData(text);
    navigate("/application");
  };

  const handleInputChange = (value) => {
    setText(value);
  };

  const handleInputClick = () => {
    setClicked(true);
  };

  const handleExampleChange = (index) => {
    setHandleExampleIndex(index);
  };

  return (
    <div className={styles.container}>
      {chordsData ? (
        <ChordComposer />
      ) : (
        <>
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <div className={styles.header}>
                <p>
                  The <span className={styles.underline}>first</span> AI
                  text-to-midi
                </p>
              </div>
              <div className={styles.leftSideContent}>
                <p>
                  Convert your words into unique chord <br /> progressions and
                  melodies.
                </p>
              </div>
              <div className={styles.exampleSection}>
                <div className={styles.lampIcon}>
                  <FaRegLightbulb />
                </div>
                <div className={styles.exampleContent}>
                  <div className={styles.exampleFirstSection}>
                    <p>Reference a song or an artist</p>
                  </div>
                  <div className={styles.exampleSecondSection}>
                    <p>Example: "{handleExampleIndex}"</p>
                  </div>
                </div>
              </div>
              <div className={styles.inputContainer}>
                <UserInput
                  onSubmit={handleSubmit}
                  onClick={handleInputClick}
                  onChange={handleInputChange}
                  setClicked={setClicked}
                  setIsLoadingGif={setIsLoadingGif}
                  value={text}
                  handleCoolDown={handleCoolDown}
                  coolDown={coolDown}
                  loginRequired={loginRequired}
                  handleSubmitBtnClick={handleSubmitBtnClick}
                />
              </div>
            </div>

            <div className={styles.rightSide}>
              {isLoadingGif ? (
                <div className={styles.homepageLoading}>
                  <Loading />
                </div>
              ) : (
                <>
                  <SliderExamples
                    midiFile={
                      "https://storage.googleapis.com/ttmusic-generated-files/B3BBnxG3V6.mid"
                    }
                    onExampleChange={handleExampleChange}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Homepage;
