import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChordsDataProvider } from "./ChordsDataContext";
import { PromptProvider } from "./promptContext";
import Navbar from "./Components/Default/Navbar/Navbar";
import Homepage from "./Pages/Homepage/Homepage";
import Footer from "./Components/Default/Footer/Footer";
import ChordComposer from "./Pages/ChordComposer/ChordComposer";
import Feedback from "./Components/Default/Navbar/Feedback/Feedback";
import { initAuth } from "./utils/authUtils";
import SignInRequired from "./Components/SignInValidation/SignInRequired";
import { validatePromptCount } from "./utils/promptsUtils";

function App() {
  const [signInClicked, setSignInClicked] = useState(false);
  const [feedbackClicked, setFeedbackClicked] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [coolDown, setCoolDown] = useState(false);
  const [loginRequired, setLoginRequired] = useState(false);
  const [submitBtnClicked, setSubmitBtnClicked] = useState(false);
  const [closeSignInRequired, setCloseSignInRequired] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      try {
        await initAuth();
      } catch (err) {
        console.log(err);
      }
    };
    handleAuth();
  }, []);

  const handleSignInClick = () => {
    setSignInClicked(!signInClicked);
    setFeedbackClicked(null);
  };

  const handleFeedbackClick = () => {
    if (loginRequired === true) {
      setLoginRequired(false);
    }
    setFeedbackClicked(!feedbackClicked);
    setSignInClicked(null);
    setBtnClicked(!btnClicked);
  };

  const handleCoolDown = (cooldown_seconds) => {
    setCoolDown(true);
    setTimeout(() => {
      setCoolDown(false);
    }, cooldown_seconds * 1000);
  };

  const handleSubmitBtnClick = (value) => {
    setSubmitBtnClicked(value);
  };

  const handleCloseSignInRequired = (value) => {
    setCloseSignInRequired(value);
    if(closeSignInRequired) {
      setSubmitBtnClicked(!closeSignInRequired);
    }
  }

  useEffect(() => {
    const checkPromptCount = () => {
      if (!validatePromptCount()) {
        setLoginRequired(true);
      } else {
        setLoginRequired(false);
      }
    };
    checkPromptCount();
  }, [submitBtnClicked, feedbackClicked]);

  return (
    <ChordsDataProvider>
      <PromptProvider>
        <Router>
          <Navbar
            handleSignInClick={handleSignInClick}
            handleFeedbackClick={handleFeedbackClick}
            setFeedbackClicked={setFeedbackClicked}
            setBtnClicked={setBtnClicked}
            feedbackBtnText="Contact us"
            btnClicked={btnClicked}
          />
          <div
            className={
              feedbackClicked || (loginRequired && submitBtnClicked) ? "darkMode" : "content"
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Homepage
                    handleCoolDown={handleCoolDown}
                    coolDown={coolDown}
                    loginRequired={loginRequired}
                    handleSubmitBtnClick={handleSubmitBtnClick}
                  />
                }
              />
              <Route
                path="/application"
                element={
                  <ChordComposer
                    handleCoolDown={handleCoolDown}
                    coolDown={coolDown}
                    loginRequired={loginRequired}
                    handleSubmitBtnClick={handleSubmitBtnClick}
                  />
                }
              />
            </Routes>
            <Footer />
          </div>
          {feedbackClicked && <Feedback />}
          {loginRequired && submitBtnClicked && <SignInRequired handleCloseSignInRequired={handleCloseSignInRequired} />}
        </Router>
      </PromptProvider>
    </ChordsDataProvider>
  );
}

export default App;
