import React, { useEffect, useState } from "react";
import styles from "./SignInWithGoogle.module.css";
import { FaGoogle } from "react-icons/fa";
import { handleSignInWithGoogle, signOut } from "../../../../utils/authUtils";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../firebaseConfig";
import UserAvatar from "./UserAvatar/UserAvater";

function SignInWithGoogle() {
  const [signIn, setSignIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setSignIn(true);
        setUser(user);
      } else {
        setSignIn(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = () => {
    handleSignInWithGoogle(() => {
      setSignIn(true);
    });
  };

  const handleLogout = () => {
    setSignIn(false);
    signOut();
  };

  return (
    <div className={styles.container}>
      {signIn ? (
        <>
          <div className={styles.userAvatar}>
            <UserAvatar name={user?.displayName} onClick={handleLogout} />
          </div>
        </>
      ) : (
        <>
          <button className={styles.googleBtn} onClick={handleSignIn}>
            <FaGoogle className={styles.icon} />
            <p>Sign in with Google</p>
          </button>
        </>
      )}
    </div>
  );
}

export default SignInWithGoogle;
