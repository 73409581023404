import React, { createContext, useContext, useState, useEffect } from "react";

const ChordsDataContext = createContext();

export const ChordsDataProvider = ({ children }) => {
  const [chordsData, setChordsData] = useState(null);

  const updateChordsData = (newData) => {
    setChordsData(newData);
    const serializedData = JSON.stringify(newData);
    localStorage.setItem("chordsData", serializedData);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("chordsData"); 
    if (storedData !== null) {
      const deserializedData = JSON.parse(storedData);
      setChordsData(deserializedData);
    }
  }, []);

  return (
    <ChordsDataContext.Provider value={{ chordsData, updateChordsData }}>
      {children}
    </ChordsDataContext.Provider>
  );
};

export const useChordsData = () => {
  const context = useContext(ChordsDataContext);
  if (!context) {
    throw new Error("useChordsData must be used within a ChordsDataProvider");
  }
  return context;
};
