import React from "react";
import styles from "./ChordList.module.css";
import ChordItem from "../ChordItem/ChordItem";

function ChordList({ chords }) {
  return (
    <div className={styles.chordList}>
      <ul className={styles.chords}>
        {chords.map((chord, id) => (
          <ChordItem key={id} chord={chord} />
        ))}
      </ul>
    </div>
  );
}

export default ChordList;
