import React from "react";
import styles from "./ChordItem.module.css";

function ChordItem({ chord }) {
  return (
    <div className={styles.chordItem}>
      <li className={styles.chordName}>{chord.chord}</li>
      <li className={styles.degree}>{chord.degree}</li>
    </div>
  );
}

export default ChordItem;
